import React, { useEffect } from "react";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ProductList from "./components/ProductList";
import Search from "./components/Search";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import ProductDetail from "./pages/Product";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import BrandManager from "./components/BrandManager";
import ProductForm from "./components/ProductForm";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Guide from "./pages/Guide";
import { makeRequest } from "./makeRequest";

function App() {
  useEffect(() => {
    setInterval(() => {
      if (localStorage.refresh) {
        makeRequest
          .post("/users/refresh", {
            refreshToken: localStorage.refresh,
          })
          .then((res) => {
            localStorage.setItem("token", res.data.newAccessToken);
            localStorage.setItem("refresh", res.data.refreshToken);
          })
          .catch((err) => {
            if (localStorage.token) {
              localStorage.removeItem("token");
            }
            localStorage.removeItem("refresh");
          });
      }
    }, 600000);
  }, []);

  return (
    <Router basename="/">
      <div className="gradient w-full min-h-fit relative m-0 flex">
        <Nav />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/brandmanager" element={<BrandManager />} />
          <Route path="/productform" element={<ProductForm />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/guide" element={<Guide />} />
          {/* <Route path="/config" element={<ProductForm />} /> */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

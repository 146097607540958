import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { makeRequest } from "../makeRequest";

const ProductForm = ({ productId }) => {
  const [selectedColor, setSelectedColor] = React.useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [loginStatus, setLoginStatus] = useState(false);
  const [size, setSize] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [disponible, setDisponible] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState("Patientez un instant");
  const [imagesLoaded, setImagesLoaded] = React.useState(false);
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    size: "",
    details: "",
    disponible: true,
    color: "",
    category: "",
    price: "",
    oldPrice: "",
    img1: null,
    img2: null,
    img3: null,
    img4: null,
    img5: null,
    img6: null,
    img7: null,
    img8: null,
  });
  const [brands, setBrands] = useState([]);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [images, setImages] = useState({
    img1: null,
    img2: null,
    img3: null,
    img4: null,
    img5: null,
    img6: null,
    img7: null,
    img8: null,
  });

  useEffect(() => {
    makeRequest
      .get("/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLoginStatus(response.data.LoggedIn ? true : false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.error("Unauthorized: Please log in again.");
          // Optionally redirect the user to a login page or show a message
          // e.g., window.location.href = '/login';
        } else {
          // Handle other errors
          console.error("An error occurred:", error.message);
        }
      });
  }, []);

  async function fetchAndSetImages() {
    try {
      const imageKeys = [
        "img1",
        "img2",
        "img3",
        "img4",
        "img5",
        "img6",
        "img7",
        "img8",
      ];

      const results = await Promise.all(
        imageKeys.map(async (key) => {
          if (product[key]) {
            const url = await fetchImg(product[key]);
            return { key, url };
          }
          return { key, url: null };
        })
      );

      const updatedImages = results.reduce((acc, { key, url }) => {
        acc[key] = url;
        return acc;
      }, {});

      setImages((prevProduct) => ({
        ...prevProduct,
        ...updatedImages,
      }));

      if (
        updatedImages.img1 ||
        updatedImages.img2 ||
        updatedImages.img3 ||
        updatedImages.img4 ||
        updatedImages.img5 ||
        updatedImages.img6 ||
        updatedImages.img7 ||
        updatedImages.img8
      ) {
        setImagesLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }

  useEffect(() => {
    fetchAndSetImages();
  }, [product]);

  async function fetchImg(image) {
    const result = await makeRequest.get("/images/" + image);
    return result?.data?.url ? result?.data?.url : null;
  }

  const handleDeleteImage = (fieldName) => {
    setImagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [fieldName]: null,
    }));
    setProduct((prevProduct) => ({
      ...prevProduct,
      [fieldName]: null,
    }));
  };

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        try {
          const response = await makeRequest.get(`/products/${productId}`);
          setProduct(response.data);
          setSelectedColor(response.data?.color);
          setSize(response.data?.size);
          setCategory(response.data?.category);
          setDisponible(response.data?.disponible);
          setIsEditing(true);
        } catch (err) {
          console.error("Failed to fetch product");
        }
      }
    };
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    const fetchBrandsData = async () => {
      try {
        const brandsData = await makeRequest.get(`/brands/`);
        setBrands(brandsData.data);
        setLoadingBrands(false);
      } catch (err) {
        console.error("Failed to fetch brands");
      }
    };

    if (!productId) {
      setImagesLoaded(true);
    }

    fetchBrandsData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  async function postImage(image) {
    const formData = new FormData();
    formData.append("image", image);

    const result = await makeRequest.post("/images/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return result.data;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isEditing) {
        if (product?.img1?.path) {
          const img1 = await postImage(product.img1);
          product.img1 = img1.name;
        }
        if (product?.img2?.path) {
          const img2 = await postImage(product.img2);
          product.img2 = img2.name;
        }

        if (product?.img3?.path) {
          const img3 = await postImage(product.img3);
          product.img3 = img3.name;
        }
        if (product?.img4?.path) {
          const img4 = await postImage(product.img4);
          product.img4 = img4.name;
        }
        if (product?.img5?.path) {
          const img5 = await postImage(product.img5);
          product.img5 = img5.name;
        }
        if (product?.img6?.path) {
          const img6 = await postImage(product.img6);
          product.img6 = img6.name;
        }
        if (product?.img7?.path) {
          const img7 = await postImage(product.img7);
          product.img7 = img7.name;
        }
        if (product?.img8?.path) {
          const img8 = await postImage(product.img8);
          product.img8 = img8.name;
        }

        await makeRequest.put(`/products/${productId}`, {
          name: product.name,
          brand: product.brand,
          size: size,
          details: product.details,
          disponible: disponible,
          color: selectedColor,
          category: category,
          price: product.price,
          oldPrice: product.oldPrice,
          img1: product.img1,
          img2: product.img2,
          img3: product.img3,
          img4: product.img4,
          img5: product.img5,
          img6: product.img6,
          img7: product.img7,
          img8: product.img8,
        });
      } else {
        if (product.img1) {
          const img1 = await postImage(product.img1);
          product.img1 = img1.name;
        }
        if (product.img2) {
          const img2 = await postImage(product.img2);
          product.img2 = img2.name;
        }
        if (product.img3) {
          const img3 = await postImage(product.img3);
          product.img3 = img3.name;
        }
        if (product.img4) {
          const img4 = await postImage(product.img4);
          product.img4 = img4.name;
        }
        if (product.img5) {
          const img5 = await postImage(product.img5);
          product.img5 = img5.name;
        }
        if (product.img6) {
          const img6 = await postImage(product.img6);
          product.img6 = img6.name;
        }
        if (product.img7) {
          const img7 = await postImage(product.img7);
          product.img7 = img7.name;
        }
        if (product.img8) {
          const img8 = await postImage(product.img8);
          product.img8 = img8.name;
        }

        await makeRequest.post("/products/", {
          name: product.name,
          brand: product.brand,
          size: size,
          details: product.details,
          disponible: disponible,
          color: selectedColor,
          category: category,
          price: product.price,
          oldPrice: product.oldPrice,
          img1: product.img1,
          img2: product.img2,
          img3: product.img3,
          img4: product.img4,
          img5: product.img5,
          img6: product.img6,
          img7: product.img7,
          img8: product.img8,
        });
      }
      setLoading(false);
    } catch (err) {
      setMsg("Error");
      console.error("Failed to save product");
    }
  };

  const handleFilesChange = (acceptedFiles) => {
    const limitedFiles = acceptedFiles.slice(0, 8); // Limit to 8 files

    let filePreviews = {
      img1: null,
      img2: null,
      img3: null,
      img4: null,
      img5: null,
      img6: null,
      img7: null,
      img8: null,
    };
    if (limitedFiles.length > 0) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        img1: null,
        img2: null,
        img3: null,
        img4: null,
        img5: null,
        img6: null,
        img7: null,
        img8: null,
      }));

      limitedFiles.forEach((file, index) => {
        if (filePreviews[`img${index + 1}`] !== undefined) {
          filePreviews[`img${index + 1}`] = URL.createObjectURL(file);
        }
      });

      const newProductImages = limitedFiles.reduce((acc, file, index) => {
        acc[`img${index + 1}`] = file;
        return acc;
      }, {});

      setImagePreviews(filePreviews);
      setProduct((prevProduct) => ({
        ...prevProduct,
        ...newProductImages,
      }));
    }
  };

  const handleFileChange = (acceptedFiles, fieldName) => {
    const file = acceptedFiles[0];
    const fileURL = URL.createObjectURL(file);
    setImagePreviews((prevPreviews) => ({
      ...prevPreviews,
      [fieldName]: fileURL,
    }));
    setProduct((prevProduct) => ({
      ...prevProduct,
      [fieldName]: file,
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleFilesChange,
    multiple: true,
  });

  const { getRootProps: getRootPropsImg1, getInputProps: getInputPropsImg1 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img1"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg2, getInputProps: getInputPropsImg2 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img2"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg3, getInputProps: getInputPropsImg3 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img3"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg4, getInputProps: getInputPropsImg4 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img4"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg5, getInputProps: getInputPropsImg5 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img5"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg6, getInputProps: getInputPropsImg6 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img6"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg7, getInputProps: getInputPropsImg7 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img7"),
      multiple: false,
    });

  const { getRootProps: getRootPropsImg8, getInputProps: getInputPropsImg8 } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => handleFileChange(acceptedFiles, "img8"),
      multiple: false,
    });
  return (
    <>
      {loginStatus && (
        <div className="w-full ">
          {loading ? (
            <div className="w-screen h-screen flex justify-center items-center flex-col gap-4">
              <h1 className="text-3xl font-extrabold">{msg}</h1>
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="mt-24 mb-36 w-full flex justify-center flex-col"
            >
              <div className="bg-black w-full my-12 text-white text-right text-5xl p-4 px-20">
                {" "}
                <p className="fade-right">
                  {isEditing ? "Update" : "Create"} Shoes
                </p>
              </div>
              <div className="grid sm:grid-cols-1 lg:grid-cols-3 m-12 gap-8">
                <div className="flex flex-col gap-2 fade-up">
                  <h1 className="text-4xl">Info</h1>{" "}
                  <FormControlLabel
                    labelPlacement="left"
                    control={
                      <Switch
                        checked={disponible}
                        onChange={() =>
                          setDisponible((prevState) => !prevState)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        color="secondary"
                      />
                    }
                    label="Disponible"
                  />
                  <TextField
                    label="Name"
                    name="name"
                    value={product.name}
                    onChange={handleChange}
                    required
                  />
                  {loadingBrands ? (
                    <CircularProgress />
                  ) : (
                    <FormControl>
                      <InputLabel required>Brand</InputLabel>
                      <Select
                        label="Brand"
                        name="brand"
                        value={product.brand}
                        onChange={handleChange}
                        required
                      >
                        {brands.length !== 0 ? (
                          brands?.map((brand) => (
                            <MenuItem key={brand._id} value={brand.name}>
                              {brand.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={"No Brands"}>No Brands</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    label="Price"
                    name="price"
                    type="number"
                    value={product.price}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    label="Old Price"
                    name="oldPrice"
                    type="number"
                    value={product.oldPrice}
                    onChange={handleChange}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" required>
                      Taille
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={size}
                      label="Taille"
                      onChange={(e) => setSize(e.target.value)}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                      required
                    >
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"4.5"}>4.5</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"5.5"}>5.5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"6.5"}>6.5</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"7.5"}>7.5</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"8.5"}>8.5</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                      <MenuItem value={"9.5"}>9.5</MenuItem>
                      <MenuItem value={"10"}>10</MenuItem>
                      <MenuItem value={"10.5"}>10.5</MenuItem>
                      <MenuItem value={"11"}>11</MenuItem>
                      <MenuItem value={"11.5"}>11.5</MenuItem>
                      <MenuItem value={"12"}>12</MenuItem>
                      <MenuItem value={"12.5"}>12.5</MenuItem>
                      <MenuItem value={"13"}>13</MenuItem>
                      <MenuItem value={"13.5"}>13.5</MenuItem>
                      <MenuItem value={"14"}>14</MenuItem>
                      <MenuItem value={"14.5"}>14.5</MenuItem>
                      <MenuItem value={"15"}>15</MenuItem>
                      <MenuItem value={"15.5"}>15.5</MenuItem>
                      <MenuItem value={"16"}>16</MenuItem>
                      <MenuItem value={"16.5"}>16.5</MenuItem>
                      <MenuItem value={"17"}>17</MenuItem>
                      <MenuItem value={"17.5"}>17.5</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                      label="Categorie"
                      name="category"
                      value={category ? category : ""}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem key={"Homme"} value={"Homme"}>
                        Homme
                      </MenuItem>
                      <MenuItem key={"Femme"} value={"Femme"}>
                        Femme
                      </MenuItem>{" "}
                      <MenuItem key={"Enfant"} value={"Enfant"}>
                        Enfant
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <TextField
                      multiline
                      label="Details"
                      name="details"
                      value={product.details}
                      onChange={handleChange}
                    ></TextField>
                  </FormControl>
                </div>
                {/*colors*/}
                <div className="w-full h-full fade-up">
                  <h1 className="text-4xl">Couleur</h1>
                  <ul className="grid grid-cols-4 gap-y-8 mt-4">
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-black cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "black"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("black")}
                      ></div>
                      <p className="text-center">Noir</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-white cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "white"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("white")}
                      ></div>
                      <p className="text-center">Blanc</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-gray-400 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "gray"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("gray")}
                      ></div>
                      <p className="text-center">Gris</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-amber-900 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "brown"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("brown")}
                      ></div>
                      <p className="text-center">Marron</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-red-500 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "red"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("red")}
                      ></div>
                      <p className="text-center">Rouge</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-blue-600 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "blue"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("blue")}
                      ></div>
                      <p className="text-center">Bleu</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-green-500 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "green"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("green")}
                      ></div>
                      <p className="text-center">Vert</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-pink-500 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "pink"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("pink")}
                      ></div>
                      <p className="text-center">Rose</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-orange-500 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "orange"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("orange")}
                      ></div>
                      <p className="text-center">Orange</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-yellow-300 cursor-pointer  border-black hover:outline-double ${
                          selectedColor === "yellow"
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("yellow")}
                      ></div>
                      <p className="text-center">Jaune</p>
                    </li>
                    <li className="w-fit">
                      <div
                        className={`w-10 h-10 mb-2 bg-white cursor-pointer font-extrabold font flex justify-center items-center border-black hover:outline-double ${
                          selectedColor === ""
                            ? "outline-8 outline-double"
                            : "border-2"
                        }`}
                        onClick={(e) => setSelectedColor("")}
                      >
                        X
                      </div>
                      <p className="text-center">Aucun</p>
                    </li>
                  </ul>
                </div>
                <div className="fade-up">
                  <h1 className="text-4xl">Images</h1>
                  <div
                    {...getRootProps()}
                    className="border-4 mb-4 border-black p-4 text-center cursor-pointer hover:text-white hover:scale-110 transition-all duration-300 hover:bg-black"
                  >
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop images here, or click to select (up to 8
                      images)
                    </p>
                  </div>
                  <div className="w-full grid grid-cols-2 gap-4">
                    {imagesLoaded &&
                      [...Array(8)].map((_, i) => {
                        const fieldName = `img${i + 1}`;
                        const rootProps =
                          i === 0
                            ? getRootPropsImg1
                            : i === 1
                            ? getRootPropsImg2
                            : i === 2
                            ? getRootPropsImg3
                            : i === 3
                            ? getRootPropsImg4
                            : i === 4
                            ? getRootPropsImg5
                            : i === 5
                            ? getRootPropsImg6
                            : i === 6
                            ? getRootPropsImg7
                            : getRootPropsImg8;

                        const inputProps =
                          i === 0
                            ? getInputPropsImg1
                            : i === 1
                            ? getInputPropsImg2
                            : i === 2
                            ? getInputPropsImg3
                            : i === 3
                            ? getInputPropsImg4
                            : i === 4
                            ? getInputPropsImg5
                            : i === 5
                            ? getInputPropsImg6
                            : i === 6
                            ? getInputPropsImg7
                            : getInputPropsImg8;

                        return (
                          <div>
                            <div
                              style={{
                                position: "relative",
                                border: "2px solid black",
                                aspectRatio: "square",
                              }}
                              className=" hover:text-white hover:scale-110 transition-all duration-300 hover:bg-black"
                            >
                              {imagePreviews[fieldName] ? (
                                <div className="">
                                  <img
                                    src={imagePreviews[fieldName]}
                                    alt={`Preview ${i + 1}`}
                                    style={{
                                      maxWidth: "100%",
                                      height: "auto",
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => handleDeleteImage(fieldName)}
                                    style={{
                                      position: "absolute",
                                      top: 10,
                                      right: 10,
                                      color: "white",
                                      backgroundColor: "rgba(0,0,0,0.6)",
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              ) : imagesLoaded && images[fieldName] ? (
                                <div className="">
                                  <img
                                    src={images[fieldName]}
                                    alt={`Preview ${i + 1}`}
                                    style={{
                                      maxWidth: "100%",
                                      height: "auto",
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => handleDeleteImage(fieldName)}
                                    style={{
                                      position: "absolute",
                                      top: 10,
                                      right: 10,
                                      color: "white",
                                      backgroundColor: "rgba(0,0,0,0.6)",
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              ) : (
                                <div
                                  className="cursor-pointer p-10 w-full h-full"
                                  {...rootProps()}
                                >
                                  <input {...inputProps()} />
                                  <p className="text-center">IMG {i + 1}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <Button variant="contained" color="secondary" type="submit">
                  {isEditing ? "Update" : "Create"} Product
                </Button>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default ProductForm;

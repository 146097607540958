import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductForm from "../components/ProductForm";
import { makeRequest } from "../makeRequest";
import RequestForm from "../components/RequestForm";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [activeImg, setActiveImage] = useState(null);
  const [images, setImages] = useState({
    img1: null,
    img2: null,
    img3: null,
    img4: null,
    img5: null,
    img6: null,
    img7: null,
    img8: null,
  });

  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    makeRequest
      .get("/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLoginStatus(response.data.LoggedIn ? true : false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.error("Unauthorized: Please log in again.");
          // Optionally redirect the user to a login page or show a message
          // e.g., window.location.href = '/login';
        } else {
          // Handle other errors
          console.error("An error occurred:", error.message);
        }
      });
  }, []);

  async function fetchProduct() {
    const result = await makeRequest.get("/products/" + id);
    setProduct(result.data);
    return result.data;
  }

  async function fetchImg(image) {
    const result = await makeRequest.get("/images/" + image);
    return result.data.url;
  }

  async function fetchAndSetImages() {
    if (product?.img1) {
      try {
        const imageKeys = [
          "img1",
          "img2",
          "img3",
          "img4",
          "img5",
          "img6",
          "img7",
          "img8",
        ];

        const results = await Promise.all(
          imageKeys.map(async (key) => {
            if (product[key]) {
              const url = await fetchImg(product[key]);
              if ([key].toString() === "img1") {
                setActiveImage(url);
              }
              return { key, url };
            }
            return { key, url: null };
          })
        );

        const updatedImages = results.reduce((acc, { key, url }) => {
          acc[key] = url;
          return acc;
        }, {});

        setImages((prevProduct) => ({
          ...prevProduct,
          ...updatedImages,
        }));
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }
  }

  useEffect(() => {
    fetchProduct();
  }, []);

  useEffect(() => {
    fetchAndSetImages();
  }, [product]);

  return (
    <div className="w-full h-full">
      <div className="flex flex-col h-full  justify-between lg:flex-row gap-16 py-36 lg:items-center">
        <div className="flex flex-col border-red-600 items-center gap-6 lg:w-2/4 fade-in">
          <img
            src={activeImg}
            alt=""
            className="w-full lg: p-4 h-full aspect-square object-cover rounded-xl"
          />
          <div className="grid grid-cols-4 gap-4 m-4 overflow-auto scroll">
            {images.img1 && (
              <img
                src={images.img1}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img1)}
              />
            )}
            {images.img2 && (
              <img
                src={images.img2}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img2)}
              />
            )}
            {images.img3 && (
              <img
                src={images.img3}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img3)}
              />
            )}
            {images.img4 && (
              <img
                src={images.img4}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img4)}
              />
            )}
            {images.img5 && (
              <img
                src={images.img5}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img5)}
              />
            )}
            {images.img6 && (
              <img
                src={images.img6}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img6)}
              />
            )}
            {images.img7 && (
              <img
                src={images.img7}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img7)}
              />
            )}
            {images.img8 && (
              <img
                src={images.img8}
                alt=""
                className="lg:w-24 sm:w-14 lg:h-24 sm:h-14 rounded-md cursor-pointer"
                onClick={() => setActiveImage(images.img8)}
              />
            )}
          </div>
        </div>
        {/* ABOUT */}
        <div className="flex flex-col  gap-4 lg:px-8 lg:w-2/4 fade-up sm:px-2 sm:text-center lg:text-left">
          <div>
            <span className=" text-violet-600 text-xl">
              {product?.brand} - {product?.category}
            </span>
            <h1 className="text-5xl">{product?.name}</h1>
            <h1 className="text-2xl">Grandeur - {product?.size}</h1>
          </div>
          <p className="text-gray-700 text-xl">{product?.details}</p>
          <div
            className={` flex gap-4 sm:justify-center lg:justify-start text-black bottom-0 w-full h-[80px] text-4xl z-10 `}
          >
            <p className="text-black">{product?.price}$</p>
            {product?.oldPrice && (
              <p
                className={` ${
                  product?.oldPrice && "text-red-500 line-through"
                }`}
              >
                {product?.oldPrice}$
              </p>
            )}
          </div>
          <div className="flex sm:flex-col  lg:flex-row items-center gap-12">
            {product && <RequestForm product={product}></RequestForm>}
          </div>
        </div>
      </div>
      {loginStatus && <ProductForm productId={product?._id}></ProductForm>}
    </div>
  );
};

export default ProductDetail;

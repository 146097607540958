import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { selectClasses } from "@mui/material/Select";
import ProductList from "./ProductList";
import { makeRequest } from "../makeRequest";

function Search() {
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [size, setSize] = React.useState("");
  const [selectedColor, setSelectedColor] = React.useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [data, setData] = useState([]);
  const [venteSpeciales, setVenteSpeciales] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [sortOrder, setSortOrder] = useState("creationDate-asc");
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [itemsPerPage] = useState(16); // Number of items per page

  const [open, setOpen] = useState(false);

  const fetchProducts = () => {
    const queryParams = new URLSearchParams();
    if (selectedBrands.length)
      selectedBrands.forEach((brand) => {
        queryParams.append("[filters][brand][]", brand);
      });
    if (selectedCategories.length)
      selectedCategories.forEach((category) => {
        queryParams.append("[filters][category][]", category);
      });
    if (size) queryParams.append("[filters][size]", size);
    if (selectedColor) queryParams.append("[filters][color]", selectedColor);
    if (venteSpeciales) queryParams.set("venteSpecial", "true");
    if (sortOrder) queryParams.set("sortOrder", sortOrder);
    if (!loginStatus) queryParams.set("disponible", "true");

    setQuery(queryParams);

    // pagination parameters
    queryParams.set("sortOrder", sortOrder);
    queryParams.set("page", page);
    queryParams.set("limit", itemsPerPage);

    makeRequest
      .get(`/products?${queryParams.toString()}`)
      .then((response) => {
        setData(response.data.products);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  useEffect(() => {
    makeRequest
      .get("/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLoginStatus(response.data.LoggedIn ? true : false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.error("Unauthorized: Please log in again.");
          // Optionally redirect the user to a login page or show a message
          // e.g., window.location.href = '/login';
        } else {
          // Handle other errors
          console.error("An error occurred:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    if (loginStatus !== null) {
      // Ensure loginStatus is set
      fetchProducts();
      makeRequest
        .get("/brands/")
        .then((response) => setBrands(response.data))
        .catch((error) => console.error("Error fetching brands:", error));
    }
  }, [page, loginStatus]);

  useEffect(() => {
    applyFilters();
  }, [selectedBrands]);

  const handleInputFocus = () => {
    setOpen(true);
  };

  const applyFilters = () => {
    setPage(1);
    fetchProducts();
  };

  const applySort = (sort) => {
    setPage(1);
    let queryParams;
    if (query) {
      queryParams = query;
      queryParams.set("sortOrder", sort);
    } else {
      queryParams = new URLSearchParams();
      queryParams.set("sortOrder", sort);
    }

    const queryString = queryParams.toString();

    makeRequest
      .get(`/products?${queryString}`)
      .then((response) => {
        setData(response.data.products);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  const removeFilters = () => {
    setSelectedBrands([]);
    setSize("");
    setSelectedColor("");
    setSelectedCategories([]);
    setPage(1);
    fetchProducts();
  };

  // Handlers for multiple choice selections
  const handleBrandChange = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="w-full min-h-fit">
      <div className=" h-fit duration-500 delay-300 mt-48 w-full flex justify-center flex-col items-center">
        <div
          className={` flex justify-between gap-4 mb-8 p-4 duration-500 ${
            open ? "h-0" : "h-[100px]"
          }`}
        >
          {!open && brands.length > 0 ? (
            brands.map((brand) => (
              <div
                key={brand._id}
                className={`lg:text-6xl sm:text-2xl italic fade-in flex font-extrabold duration-700 hover:translate-y-[-10px] hover:text-purple-500 cursor-pointer ${
                  selectedBrands.includes(brand.name) &&
                  "text-purple-700 translate-y-[-10px]"
                } `}
                onClick={() => {
                  if (!selectedBrands.includes(brand.name)) {
                    setSelectedBrands([]);
                    handleBrandChange(brand.name);
                    applyFilters();
                  } else {
                    setSelectedBrands([]);
                    removeFilters();
                  }
                }}
              >
                {brand.name}
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
        <div
          className={`box relative shadow-2xl rounded-full ${open && "search"}`}
        >
          <form name="search">
            <div
              className={`input flex justify-center items-center bg-black ${
                !open && "cursor-pointer"
              } ${open ? "bg-black" : "bg-white"}`}
              onClick={handleInputFocus}
            >
              <p
                className={`opacity-0 select-none duration-500 ${
                  open && "text-white"
                }`}
              >
                Filtres
              </p>
            </div>
          </form>
          <SearchIcon className="icon" fontSize="large" />
        </div>

        <div
          className={`${
            open ? "h-[500px] opacity-100" : "h-0 opacity-0"
          } w-3/4 shadow-2xl overflow-hidden transition-all duration-500 relative flex flex-col left-0 right-0  top-full mt-2 bg-white border border-gray-400 `}
        >
          {/* Filter menu content */}
          <div className="flex sm:flex-col  h-5/6 w-full p-8 border-b-2">
            <div className="flex sm:overflow-y-scroll lg:overflow-hidden scroll-purple justify-evenly h-[500px] sm:flex-col lg:flex-row w-full gap-8">
              <div className="sm:w-full lg:w-1/4 h-full flex flex-col">
                <h1 className="text-4xl">Marque</h1>
                <ul className="text-xl max-h-40 overflow-auto">
                  {brands.length > 0 ? (
                    brands.map((brand) => (
                      <li key={brand._id}>
                        <Checkbox
                          checked={selectedBrands.includes(brand.name)}
                          onChange={() => handleBrandChange(brand.name)}
                        />
                        {brand.name}
                      </li>
                    ))
                  ) : (
                    <p>No Brands</p>
                  )}
                </ul>
              </div>
              <div className="sm:w-full lg:w-1/4 h-full flex flex-col sm:gap-8 lg:gap-0">
                <div className="h-1/2">
                  <h1 className="text-4xl">Catégorie</h1>
                  <ul className="text-xl">
                    <li>
                      <Checkbox
                        color="secondary"
                        checked={selectedCategories.includes("Homme")}
                        onChange={() => handleCategoryChange("Homme")}
                      ></Checkbox>
                      Homme
                    </li>
                    <li>
                      <Checkbox
                        color="secondary"
                        checked={selectedCategories.includes("Femme")}
                        onChange={() => handleCategoryChange("Femme")}
                      ></Checkbox>
                      Femme
                    </li>
                    <li>
                      <Checkbox
                        color="secondary"
                        checked={selectedCategories.includes("Enfant")}
                        onChange={() => handleCategoryChange("Enfant")}
                      ></Checkbox>
                      Enfant
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col sm:w-full lg:w-1/4 h-auto  sm:gap-8 lg:gap-0">
                <div className="h-1/2 ">
                  <h1 className="text-4xl">Grandeur</h1>
                  <Box sx={{ maxWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Taille
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={size}
                        label="Taille"
                        onChange={(e) => setSize(e.target.value)}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                      >
                        <MenuItem value={"4"}>4</MenuItem>
                        <MenuItem value={"4.5"}>4.5</MenuItem>
                        <MenuItem value={"5"}>5</MenuItem>
                        <MenuItem value={"5.5"}>5.5</MenuItem>
                        <MenuItem value={"6"}>6</MenuItem>
                        <MenuItem value={"6.5"}>6.5</MenuItem>
                        <MenuItem value={"7"}>7</MenuItem>
                        <MenuItem value={"7.5"}>7.5</MenuItem>
                        <MenuItem value={"8"}>8</MenuItem>
                        <MenuItem value={"8.5"}>8.5</MenuItem>
                        <MenuItem value={"9"}>9</MenuItem>
                        <MenuItem value={"9.5"}>9.5</MenuItem>
                        <MenuItem value={"10"}>10</MenuItem>
                        <MenuItem value={"10.5"}>10.5</MenuItem>
                        <MenuItem value={"11"}>11</MenuItem>
                        <MenuItem value={"11.5"}>11.5</MenuItem>
                        <MenuItem value={"12"}>12</MenuItem>
                        <MenuItem value={"12.5"}>12.5</MenuItem>
                        <MenuItem value={"13"}>13</MenuItem>
                        <MenuItem value={"13.5"}>13.5</MenuItem>
                        <MenuItem value={"14"}>14</MenuItem>
                        <MenuItem value={"14.5"}>14.5</MenuItem>
                        <MenuItem value={"15"}>15</MenuItem>
                        <MenuItem value={"15.5"}>15.5</MenuItem>
                        <MenuItem value={"16"}>16</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="h-1/2">
                  <h1 className="text-4xl">Vente</h1>
                  <ul className="text-xl">
                    <li>
                      <Checkbox
                        color="secondary"
                        checked={venteSpeciales}
                        onChange={() => {
                          setVenteSpeciales(!venteSpeciales);
                        }}
                      ></Checkbox>
                      Vente Speciales
                    </li>
                  </ul>
                </div>
              </div>
              {/*colors*/}
              <div className="sm:w-full lg:w-1/4 h-full flex flex-col">
                <h1 className="text-4xl">Couleur</h1>
                <ul className="grid grid-cols-4 gap-y-8 mt-4">
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-black cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "black"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("black")}
                    ></div>
                    <p className="text-center">Noir</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-white cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "white"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("white")}
                    ></div>
                    <p className="text-center">Blanc</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-gray-400 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "gray"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("gray")}
                    ></div>
                    <p className="text-center">Gris</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-amber-900 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "brown"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("brown")}
                    ></div>
                    <p className="text-center">Marron</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-red-500 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "red"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("red")}
                    ></div>
                    <p className="text-center">Rouge</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-blue-600 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "blue"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("blue")}
                    ></div>
                    <p className="text-center">Bleu</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-green-500 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "green"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("green")}
                    ></div>
                    <p className="text-center">Vert</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-pink-500 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "pink"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("pink")}
                    ></div>
                    <p className="text-center">Rose</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-orange-500 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "orange"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("orange")}
                    ></div>
                    <p className="text-center">Orange</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-yellow-300 cursor-pointer  border-black hover:outline-double ${
                        selectedColor === "yellow"
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("yellow")}
                    ></div>
                    <p className="text-center">Jaune</p>
                  </li>
                  <li className="w-fit">
                    <div
                      className={`w-10 h-10 mb-2 bg-white cursor-pointer font-extrabold font flex justify-center items-center border-black hover:outline-double ${
                        selectedColor === ""
                          ? "outline-8 outline-double"
                          : "border-2"
                      }`}
                      onClick={(e) => setSelectedColor("")}
                    >
                      X
                    </div>
                    <p className="text-center">Aucun</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-center h-12 gap-4 my-4 w-full">
            {" "}
            <Button
              variant="contained"
              color="secondary"
              sx={{ fontWeight: "800", background: "black" }}
              onClick={applyFilters}
            >
              Appliquer
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={(e) => {
                setOpen(false);
                removeFilters();
              }}
              sx={{ fontWeight: "800" }}
            >
              Effacer
            </Button>
          </div>
        </div>
      </div>{" "}
      <div className="w-full flex justify-end sm:pr-12 lg:pr-32 mt-8">
        <FormControl>
          <InputLabel id="sort-label">Sort by</InputLabel>
          <Select
            labelId="sort-label"
            id="sort-select"
            value={sortOrder}
            label="Sort by"
            onChange={(e) => {
              setSortOrder(e.target.value);
              applySort(e.target.value);
            }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
          >
            <MenuItem value={"creationDate-asc"}>Nouveautés</MenuItem>
            <MenuItem value={"price-asc"}>Price: Low to High</MenuItem>
            <MenuItem value={"price-desc"}>Price: High to Low</MenuItem>
          </Select>
        </FormControl>{" "}
      </div>
      <div className="w-full flex flex-col">
        {" "}
        <div className="flex justify-center gap-4 mt-4">
          <Button
            disabled={page <= 1}
            onClick={() => handlePageChange(page - 1)}
          >
            Precedente
          </Button>
          <span>
            Page {page} - {totalPages}
          </span>
          <Button
            disabled={page >= totalPages}
            onClick={() => handlePageChange(page + 1)}
          >
            Suivante
          </Button>
        </div>
      </div>
      {data && <ProductList data={data} />}
      <div className="w-full flex flex-col mb-8">
        {" "}
        <div className="flex justify-center gap-4 mt-4">
          <Button
            disabled={page <= 1}
            onClick={() => handlePageChange(page - 1)}
          >
            Precedente
          </Button>
          <span>
            Page {page} - {totalPages}
          </span>
          <Button
            disabled={page >= totalPages}
            onClick={() => handlePageChange(page + 1)}
          >
            Suivante
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Search;
